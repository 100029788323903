import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Html from "@components/html"
import ResourceHeader from "@components/resources/header"
import Container from "@components/layout/container"
import PropTypes from "prop-types"
import Flex from "@components/elements/flex"
import { ResourceGrid } from "@components/resources/resource-grid"
import theme from "@styles/theme"
import { ResourceWrapper } from "@components/resources/resource-wrapper"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const KpiExampleParentCategory = ({ data }) => {
  const category = data.category
  const categories = category.categories
  const image = category.image
  const imageUrl = image?.cdn

  return (
    <Layout
      image={imageUrl}
      fullWidth={true}
      seo={category.seo}
      body={category.body}
      date_updated={category.date_updated}
    >
      <article>
        <ResourceWrapper
          margin="0 0 6rem"
          marginMd="0 0 4rem"
          marginSm="0 0 2rem"
        >
          <ResourceHeader
            name={category.h1 ?? `${category.name} Metrics & KPIs`}
            alt={`${category.name} Metrics & KPI Examples`}
            subtitle={category.subtitle}
            newsletterCta
            ctaCategory={category.name}
            image={image}
            shadow={false}
            kpi
            links={[
              { text: "Resources", url: "/resources" },
              { text: "KPI Examples", url: "/resources/kpi-examples" },
              {
                text: category.name,
              },
            ]}
          />
          <Container text>
            <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
              <Html
                parseElements
                inherit
                html={category.body}
                images={category.body_images}
                cta
              />
            </Flex>
          </Container>
          {category.show_related && (
            <Container>
              <Flex
                fontSize={theme.defaults.fontSize.p}
                gap="4rem"
                margin="8rem 0"
              >
                {categories.map(category => {
                  return (
                    <ResourceGrid
                      parentSlug={category.slug}
                      key={`resourceGrid-${category.id}`}
                      title={category.name}
                      suffix={`Metrics & KPIs`}
                      kpi
                      related
                      examples={category.kpi_examples}
                    />
                  )
                })}
              </Flex>
            </Container>
          )}
        </ResourceWrapper>
        <KlipsEOPCta />
      </article>
    </Layout>
  )
}

KpiExampleParentCategory.propTypes = {
  data: PropTypes.object.isRequired,
}

export default KpiExampleParentCategory

export const pageQuery = graphql`
  query KpiExamplesParentCategoryPageQuery($id: String!) {
    category: kpiExamplesParentCategory(id: { eq: $id }) {
      categories {
        id
        title
        subtitle
        slug
        name
        kpi_examples {
          title
          subtitle
          slug
          status
          image {
            id
            title
            cdn
            placeholder
          }
        }
      }
      image {
        directus_id
        id
        title
        cdn
        placeholder
      }
      body
      body_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      name
      id
      h1
      subtitle
      date_updated
      slug
      status
      seo {
        ...seoData
      }
      show_related
    }
  }
`
